import { Text, TextProps } from '@mantine/core';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToDecimal } from 'hooks/useToDecimal';
import { useRouter } from 'next/router';
import { useUserSettings } from 'recoil/userSettings';
import { compactNumber } from 'utils/text';
export interface TokenAmountProps extends TextProps {
  amount: bigint | string | undefined;
  decimals: number | undefined;
  maxDecimals?: number;
  symbol?: string;
  isHidable?: boolean;
  withSymbol?: boolean;
  useCompactDisplay?: boolean;
}
export const TokenAmount = ({
  amount = 0n,
  decimals = 12,
  maxDecimals = 2,
  symbol = '',
  isHidable = false,
  withSymbol = true,
  useCompactDisplay = false,
  ...textProps
}: TokenAmountProps) => {
  const isMounted = useIsMounted();
  const {
    locale = 'en'
  } = useRouter();
  const {
    areBalancesVisible
  } = useUserSettings();
  let amountString = useToDecimal(amount, decimals, maxDecimals, useCompactDisplay);
  if (useCompactDisplay) {
    amountString = compactNumber(locale, amountString);
  }
  if (!isMounted) {
    return null;
  }
  return <Text {...textProps} data-sentry-element="Text" data-sentry-component="TokenAmount" data-sentry-source-file="TokenAmount.tsx">
      {isHidable && !areBalancesVisible ? '****' : amountString}{' '}
      {withSymbol ? symbol : ''}
    </Text>;
};